// @ts-ignore
import { createRoot, Root } from 'react-dom/client';
import '@ui5/webcomponents/dist/Assets.js';
import '@ui5/webcomponents-fiori/dist/Assets.js';
import '@ui5/webcomponents-react/dist/Assets.js';
import App from './App';
import axiosWrapper from './axios/axiosWrapper';
import './styles/index.less';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';

import { setLanguage as setUi5Language } from '@ui5/webcomponents-base/dist/config/Language.js';
import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme.js';
import { THEME_LIST } from './common/Utils';
import { FetchFunctions } from './types';

const { setLanguage } = eureka.I18nProvider;
const { getThemeId, setThemeId, getDefaultThemeId, setDefaultThemeId, setThemeList } =
  eurekaMgrs.AppearanceManager;
const { eventBus } = eurekaMgrs;

// enable fiori next horizon theme
setDefaultThemeId('sap_horizon');
const themeId =
  new URLSearchParams(window.location.search).get('sap-ui-theme') || getDefaultThemeId();
setThemeList(THEME_LIST);
setThemeId(themeId);
setTheme(getThemeId());

/* istanbul ignore next */
eventBus.on('i18n-update', (langCode) => {
  setLanguage(langCode);
  setUi5Language(eureka.I18nProvider.getUi5Language(langCode));
});

/* istanbul ignore next */
eventBus.on('appearance-update', (settings) => {
  console.log(`Shell UI: appearance settings is ${JSON.stringify(settings)}`);
  const { themeChanged, themeId } = settings;
  if (themeChanged) {
    setThemeId(themeId);
    setTheme(getThemeId());
  }
});

const container: HTMLElement | null = document.getElementById('rgp-app');
const fetchFunctions = axiosWrapper() as FetchFunctions;
if (container) {
  const root: Root = createRoot(container);
  root.render(<App {...fetchFunctions} />);
}

export default {};
