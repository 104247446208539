export const NOTIFICATION_MESSAGE_TYPE = {
  INTEGRATION: {
    name: 'INTEGRATION',
    promotionChangeActions: [
      'P_ACTION_DELETE',
      'P_ACTION_RELEASE',
      'P_ACTION_SUBMIT',
      'P_ACTION_RELEASE_SUCCEEDED',
      'P_ACTION_DELETE_SUCCEEDED',
    ],
  },
  ACCOUNT_PLAN: {
    name: 'ACCOUNT_PLAN',
    accountPlanChangeActions: ['UPLOAD_ACCOUNT_PLAN_BASELINE'],
  },
  JOULE: {
    name: 'JOULE',
    jouleActions: ['JOULE_PRICE_CHANGE', 'JOULE_PRODUCT_GROUP'],
  },
};

export const NOTIFICATION_ACTION_TYPE = {
  NAVIGATION: 'NAVIGATION',
};

export const NOTIFICATION_BO_TYPE = {
  ResponsibilityArea: 'ResponsibilityArea',
};
