/* eslint-disable operator-linebreak */
import { useEffect, useRef, useState, useCallback } from 'react';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import { MessageStrip } from 'src/common/ui5dependencies';
import { MessageStripDesign } from 'src/common/ui5dependencies';
import { Toast } from 'src/common/ui5dependencies';
import { ToastPlacement } from 'src/common/ui5dependencies';
import { ToastDomRef } from 'src/common/ui5dependencies';
import { css } from '@emotion/css';

const { eventBus } = eurekaMgrs;
const { useTranslation } = eureka.I18nProvider;

const styles = {
  toast: css`
    padding: 0;
    border: none;
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    width: auto;
    max-width: 60%;
    z-index: 120;
  `,
};

const MessageStripTypeEnum = {
  success: MessageStripDesign.Positive,
  warn: MessageStripDesign.Critical,
  warning: MessageStripDesign.Critical,
  info: MessageStripDesign.Information,
  error: MessageStripDesign.Negative,
  default: MessageStripDesign.Information,
};

const checkIsToastPlacement = (str: string): str is ToastPlacement => str in ToastPlacement;

const ToastPlugin = () => {
  const { t } = useTranslation();
  const toastRef = useRef<ToastDomRef>(null);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastProps, setToastProps] = useState({
    toastType: 'default',
    toastDuration: 3000,
    toastContent: '',
    toastPlacement: ToastPlacement.BottomCenter,
    toastIcon: null,
    iconContainerStyles: {},
    iconStyles: {},
    hideCloseButton: false,
    messageStripType: MessageStripDesign.Negative,
  });
  let className = ' eureka-message-toast-layout ';
  className += `eureka-message-toast-type-${toastProps.toastType}`;
  const messageHandler = (message: {
    type: string;
    content: string;
    duration: number;
    placement: string;
    hideCloseButton: boolean;
  }) => {
    if (message.content) {
      setToastProps({
        ...toastProps,
        toastType: message.type,
        toastContent: message.content,
        toastDuration: message.duration,
        toastPlacement:
          (checkIsToastPlacement(message.placement) && message.placement) ||
          ToastPlacement.BottomCenter,
        messageStripType: MessageStripTypeEnum[message.type],
        hideCloseButton: message.hideCloseButton,
      });
    }
  };

  useEffect(() => {
    eventBus.on('on-message-toast', messageHandler);
    return () => {
      eventBus.detach('on-message-toast', messageHandler);
    };
  }, []);
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `.ui5-toast-root {
        padding: 0 !important;
        box-shadow: none !important;
        max-width: none !important;
      }`;
    if (toastRef.current && toastProps.toastContent && toastProps.toastContent !== '') {
      setTimeout(() => {
        toastRef.current?.shadowRoot?.prepend(style);
        setIsToastOpen(true);
      });
    }
    return () => {
      style.remove();
    };
  }, [toastProps]);
  return (
    <Toast
      ref={toastRef}
      className={styles.toast}
      duration={toastProps.toastDuration}
      placement={toastProps.toastPlacement}
      open={isToastOpen}
      onClose={() => {
        setIsToastOpen(false);
      }}
    >
      <div className={className} style={{ display: 'flex', textAlign: 'left' }}>
        <MessageStrip
          design={toastProps.messageStripType}
          hideCloseButton={toastProps.hideCloseButton}
          hideIcon={false}
          onClose={() => {
            setIsToastOpen(false);
          }}
        >
          {t(toastProps.toastContent)}
        </MessageStrip>
      </div>
    </Toast>
  );
};

export default ToastPlugin;
