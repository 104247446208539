/* eslint-disable arrow-body-style */
/* This is the Root component mainly initializes Redux and React Router. */
import '@ui5/webcomponents-icons/dist/v5/menu';
import '@ui5/webcomponents-icons/dist/v5/menu2';
import '@ui5/webcomponents-icons/dist/v5/create';
import '@ui5/webcomponents-icons/dist/v5/overview-chart';
import '@ui5/webcomponents-icons/dist/v5/history';
import '@ui5/webcomponents-icons/dist/v5/user-settings';
import '@ui5/webcomponents-icons/dist/v5/log';
import '@ui5/webcomponents-icons/dist/v5/loan';
import '@ui5/webcomponents-icons/dist/v5/basket';
import '@ui5/webcomponents-icons/dist/v5/attachment-photo';
import '@ui5/webcomponents-icons/dist/v5/business-objects-experience';
import '@ui5/webcomponents-icons/dist/home';
import eurekaMgrs from '@eureka/ui-managers';
import '@eureka/ui-managers/src/styles/layout.css';
import { ThemeProvider, MessageStrip, MessageStripDesign } from 'src/common/ui5dependencies';
import '@ui5/webcomponents/dist/features/InputSuggestions.js';
import eureka from 'eureka';
import React, { useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { AxiosResponse } from 'axios';
import { History } from 'history';
import { redenrRoutes, listenToEventBus, mergeSettings } from './App.helper';
import history from './common/history';
import routeConfig from './common/routeConfig';
import store from './common/store';
import { getURLParam, TestingLocales, setDocumentLang } from './common/Utils';
import { MicroFrontend } from './features/common';
import { useAddonMicroFrontend } from './features/common/useAddonMicroFrontend';
import { getRandom } from './features/common/Utils';
import { setLanguage as setUi5Language } from '@ui5/webcomponents-base/dist/config/Language.js';
import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme.js';
import {
  AppState,
  SetAppState,
  MFE,
  SetMFE,
  ConfigJson,
  UserInfo,
  UserRef,
  User,
  CSRFTokenInfo,
  FetchFunctions,
  Settings,
  SettingsRef,
  isPromiseFulfilledResult,
  isPromiseRejectedResult,
} from './types';
import { eventBus } from './features/common/UserProfile/eureka';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
const { MFERouter } = eureka.components;

const { initI18n, setLanguage, getUi5Language } = eureka.I18nProvider;
const { Spinner } = eureka.components;
const { addConfig, getConfig, setCsrfToken, updateFeatureToggle } = eurekaMgrs.ConfigManager;
const configManagerSetLanguage = eurekaMgrs.ConfigManager.setLanguage;
const configManagerGetLanguage = eurekaMgrs.ConfigManager.getLanguage;
const { getDefaultThemeId, getThemeId, setThemeId } = eurekaMgrs.AppearanceManager;

type Props = FetchFunctions;
type ConfigJsonRes = AxiosResponse<ConfigJson>;
type UserInfoRes = AxiosResponse<UserInfo>;
type CSRFTokenInfoRes = AxiosResponse<CSRFTokenInfo>;
type FetchRes = ConfigJsonRes | UserInfoRes | CSRFTokenInfoRes;

let config: ConfigJson;
let lng = 'en-US';
let themeId = getDefaultThemeId();
const testingLng = getURLParam(window.location.search, 'sap-ui-language');
if (testingLng) {
  lng = TestingLocales[testingLng] ?? testingLng;
  // setLanguage(lng);
  configManagerSetLanguage(lng);
  setUi5Language(lng);
  // setDocumentLang(document, lng);
}
const testingThemeId = getURLParam(window.location.search, 'sap-ui-theme');

const getDataFromResults = ({ results, index, defValue = {} }) => {
  return Array.isArray(results) && results.length > index && results[index]?.data
    ? results[index]?.data
    : defValue;
};

const onFetchConfigSuccess = ({
  manifest,
  // state,
  setState,
  setMicroFrontends,
  setAddonMicroFrontends,
}: {
  manifest: ConfigJson;
  // state: AppState;
  setState: SetAppState;
  setMicroFrontends: SetMFE;
  setAddonMicroFrontends: SetMFE;
}) => {
  const shell = eureka.I18nProvider.getLocation(manifest, manifest['shell-ui']);
  const microFrontends: MFE[] = [];
  const addonMicroFrontends: MFE[] = [];
  manifest.components.forEach((component) => {
    const host = eureka.I18nProvider.getLocation(manifest, component);
    const mfe = {
      name: component.config.app,
      host,
      routers: component.config.routers,
    };
    if (component.config.addon) {
      addonMicroFrontends.push(mfe);
    } else {
      microFrontends.push(mfe);
    }
  });
  config = manifest;
  setState((prevState) => ({
    ...prevState,
    config,
  }));
  setMicroFrontends(microFrontends);
  setAddonMicroFrontends(addonMicroFrontends);
  // add app config into config manager
  addConfig('appConfig', config);
  // init i18n
  // i18next configuration: https://www.i18next.com/overview/configuration-options
  initI18n(
    {
      shell,
    },
    {
      debug: process.env.NODE_ENV === 'production',
      lowerCaseLng: false,
      fallbackLng: 'en-US',
      fallbackNS: 'shell',
      whitelist: false,
      lng, // en-US en-US-sappsd
      load: 'currentOnly',
      defaultNS: 'shell',
      ns: 'shell',
      preload: [lng], // en-US en-US-sappsd
      react: {
        useSuspense: false,
        wait: false,
      },
    },
  );
  // Handle error page
  if (window.location.pathname.startsWith('/error')) {
    setState((prevState) => ({
      ...prevState,
      initializing: false,
    }));
  }
};

const onFetchConfigFailed = ({ error }) => {
  console.error(error);
};

const onFetchCsrfSuccess = ({ csrf }: { csrf: CSRFTokenInfoRes }) => {
  setCsrfToken(csrf?.headers['x-csrf-token']);
};

export const onFetchCsrfFailed = ({
  error,
  user,
  // state,
  setState,
}: {
  error: any;
  user: UserRef;
  // state: AppState;
  setState: SetAppState;
}) => {
  console.log(`${error}`);
  // set a fake csrf token
  setCsrfToken('fakecsrftoken');
  setState((prevState) => ({
    ...prevState,
    settings: {},
    user: user.current,
    fetchConfigError: false,
  }));
};

export const onFetchSettingsSuccess = ({
  results,
  rawSettings,
  // state,
  setState,
  user,
}: {
  results: [any, any, any?];
  rawSettings: SettingsRef;
  // state: AppState;
  setState: SetAppState;
  user: UserRef;
}) => {
  const userProfile = getDataFromResults({ results, index: 0 });
  // If profileTimeZone is null, use UTC+0:00 (Etc/UTC) as default time zone
  userProfile.timeZone = userProfile?.profileTimeZone || 'Etc/UTC';
  rawSettings.current = {
    ...rawSettings.current,
    basicSetup: getDataFromResults({ results, index: 0 }),
    userProfile,
    companyProfile: getDataFromResults({ results, index: 1 }),
  };
  const settings = mergeSettings(rawSettings.current);
  // TODO: after basicSetup, userProfile, companyProfile initialized, then change index from 0 to 3
  const currentUser = getDataFromResults({ results, index: 0 });
  const currentUserPermissions = getDataFromResults({ results, index: 1 });
  const fundToggle = getDataFromResults({ results, index: 3 });
  addConfig(
    'CurrentUserPermissions',
    (currentUserPermissions?.roles || []).map((p) => p.toUpperCase()),
  );
  addConfig('CurrentUser', currentUser);
  addConfig('ThemeSetting', currentUser?.themeId);
  addConfig('FundToggle', fundToggle?.toggle);
  updateFeatureToggle(getDataFromResults({ results, index: 2 })?.resultList);
  // updateFeatureToggle('rgp.9545.joule.rgm.integration.demo', true);
  themeId = currentUser?.themeId || getDefaultThemeId();
  if (rawSettings.current.userProfile && rawSettings.current.userProfile.language) {
    lng = rawSettings.current.userProfile.language;
  } else if (rawSettings.current.basicSetup && rawSettings.current.basicSetup.language) {
    lng = rawSettings.current.basicSetup.language;
  }
  user.current.databaseUserId = rawSettings.current.basicSetup?.id;
  setState((prevState) => ({
    ...prevState,
    settings,
    ...(rawSettings.current.userProfile ? { user: rawSettings.current.userProfile } : null),
  }));
};

export const onFetchSettingsFinally = ({
  rawSettings,
  // state,
  setState,
}: {
  rawSettings: SettingsRef;
  // state: AppState;
  setState: SetAppState;
}) => {
  if (testingLng) {
    lng = TestingLocales[testingLng] ?? testingLng;
  }
  if (testingThemeId) {
    themeId = testingThemeId;
  }
  setThemeId(themeId);
  setTheme(getThemeId());
  setLanguage(lng);
  configManagerSetLanguage(lng);
  setUi5Language(lng);
  setDocumentLang(document, lng);
  // set initialization done
  setState((prevState) => ({
    ...prevState,
    initializing: false,
  }));
};

const processSettledResult = (
  result: PromiseSettledResult<FetchRes>,
  successCb: (any) => void,
  failedCb: (any) => void,
) => {
  if (isPromiseFulfilledResult(result)) {
    successCb && successCb(result.value);
  } else if (isPromiseRejectedResult(result)) {
    failedCb && failedCb(result.reason);
    throw result.reason;
  }
};

export const loader = () => <div>Loading...</div>;

const renderInitializing = () => {
  return (
    <div
      className="app-loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner cssClass="page-load-spinner" />
    </div>
  );
};

export const renderError = (msg: string) => (
  <MessageStrip
    style={{ marginTop: '10px', marginRight: '10px' }}
    design={MessageStripDesign.Negative}
    icon="error"
    hideIcon={false}
    hideCloseButton
  >
    {msg}
  </MessageStrip>
);

export const MicroFrontendWrapper = ({
  history,
  host,
  name,
  settings,
  user,
}: {
  history: History;
  host: string;
  name: string;
  settings: Settings;
  user: UserRef;
}) => {
  if (!settings) {
    console.error('Settings for microfrontends is empty, which is not allowed');
    return null;
  }
  return (
    <MicroFrontend
      history={history}
      match={{}}
      host={host}
      name={name}
      config={config}
      settings={settings}
      user={user.current}
      // eventBus={eventBus}
    />
  );
};

export const renderMicroFrontendRoutes = ({
  mfdRouters,
  history,
  settings,
  user,
}: {
  mfdRouters: MFE[];
  history: History;
  settings: Settings;
  user: UserRef;
}) => {
  const routes: React.ReactElement[] = [];
  (mfdRouters || []).forEach((app) => {
    (app?.routers || []).forEach((route) => {
      routes.push(
        <Route
          key={route + getRandom()}
          path={route}
          element={
            <MicroFrontendWrapper
              host={app.host}
              name={app.name}
              history={history}
              settings={settings}
              user={user}
            />
          }
        />,
      );
    });
  });
  return routes;
};

export const renderMfes = ({
  state,
  user,
  microFrontends,
}: {
  state: AppState;
  user: UserRef;
  microFrontends: MFE[];
}) => {
  console.log('router', routeConfig);
  const containerRoutes = redenrRoutes(routeConfig, '/', config, state.settings, eventBus, user);
  const microFrontendRoutes = renderMicroFrontendRoutes({
    mfdRouters: microFrontends,
    history,
    settings: state.settings,
    user,
  });
  return (
    <ThemeProvider>
      <Provider store={store}>
        <MFERouter history={history}>
          {/* <WebAssistant lang={configManagerGetLanguage() || 'en-US'} /> */}
          <Routes>
            {microFrontendRoutes}
            {containerRoutes}
          </Routes>
        </MFERouter>
      </Provider>
    </ThemeProvider>
  );
};

async function onApplicationReady({
  fetchConfig,
  fetchCsrf,
  fetchSettings,
  rawSettings,
  setState,
  setMicroFrontends,
  user,
  setAddonMicroFrontends,
}) {
  const [configRes, csrfRes] = await Promise.allSettled<FetchRes>([
    fetchConfig(),
    // fetchAuth(),
    fetchCsrf(),
  ]);
  console.log('configRes', configRes, csrfRes);
  try {
    processSettledResult(
      configRes,
      (value) =>
        onFetchConfigSuccess({
          manifest: value?.data,
          setState,
          setMicroFrontends,
          setAddonMicroFrontends,
        }),
      (error) => onFetchConfigFailed({ error }),
    );
    processSettledResult(
      csrfRes,
      (value) => onFetchCsrfSuccess({ csrf: value }),
      (error) => onFetchCsrfFailed({ error, user, setState }),
    );
  } catch (error) {
    console.error(error.message);
  }

  if (isPromiseFulfilledResult(configRes) && isPromiseFulfilledResult(csrfRes)) {
    try {
      const settingsRes = await fetchSettings();
      const userInfo = settingsRes[0]?.data;
      addConfig('user', userInfo);
      user.current = getConfig('user');
      onFetchSettingsSuccess({
        results: settingsRes,
        setState,
        rawSettings,
        user,
      });
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        settings: {},
        user: user.current,
        fetchConfigError: false,
      }));
    }
  }

  onFetchSettingsFinally({ rawSettings, setState });
}

const App: React.FC<Props> = ({ fetchConfig, fetchAuth, fetchCsrf, fetchSettings }) => {
  const [state, setState] = useState<AppState>({
    initializing: true,
    fetchConfigError: false,
    authUserError: false,
    config: {},
    settings: { basicSetup: {}, userProfile: {}, companyProfile: {} },
    user: {},
  });
  const [microFrontends, setMicroFrontends] = useState<MFE[] | []>([]);
  const [addonMicroFrontends, setAddonMicroFrontends] = useState<MFE[] | []>([]);
  const user = useRef<UserInfo | Record<string, unknown>>({});
  const rawSettings = useRef<Settings>({ basicSetup: {}, userProfile: {}, companyProfile: {} });
  const { mountAddonMfes, unmountAddonMfes } = useAddonMicroFrontend(
    addonMicroFrontends,
    state.config,
    state.user,
    state.settings,
  );

  useEffect(() => {
    addConfig('LocalEnvs', ['localhost', '127.0.0.1']);
    addConfig('loginRedirectURL', '/');
    onApplicationReady({
      fetchConfig,
      fetchCsrf,
      fetchSettings,
      rawSettings,
      user,
      setState,
      setMicroFrontends,
      setAddonMicroFrontends,
    });
    // return () => {};
  }, [fetchAuth, fetchConfig, fetchCsrf, fetchSettings]);

  useEffect(() => {
    console.log('rawSettings', history);
    listenToEventBus({ rawSettings, state, setState, history });
    console.log('State Updated', state);
  }, [state]);

  useEffect(() => {
    if (state.initializing) return;
    mountAddonMfes();
    return () => {
      unmountAddonMfes();
    };
  }, [state.initializing, mountAddonMfes, unmountAddonMfes]);

  if (state.fetchConfigError) {
    return renderError('Failed to load config, please try again.');
  }

  if (state.authUserError) {
    return renderError('Failed to get user information, please refresh page.');
  }

  if (state.initializing) {
    return renderInitializing();
  }

  return renderMfes({ state, user, microFrontends });
};

export default App;
