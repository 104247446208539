/* istanbul ignore next */
import {
  setCustomElementsScopingSuffix,
  setCustomElementsScopingRules,
} from '@ui5/webcomponents-base/dist/CustomElementsScope';

const mateName = 'ui5-suffix';

function getUi5SuffixAndSetMetaTag(_suffix, key?) {

  const defaultSuffix = `${process.env.APP_NAME}_${process.env.LIB_VERSION}`
  const suffix = _suffix ?? defaultSuffix;
  const metaTags = document.getElementsByTagName('meta');

  let suffixMetaTag;
  
  for(const tagItem of metaTags) {
    if(tagItem.name === mateName){
      suffixMetaTag = tagItem;
      break;
    }
  }

  if(suffixMetaTag) {
    let contentObj;
    try {
       contentObj = JSON.parse(suffixMetaTag.content)
    } catch(e){
      contentObj = {};
    }
    contentObj[ key || 'default' ] = suffix;
    suffixMetaTag.content = JSON.stringify(contentObj);
  } else {
    const contentObj = {};
    contentObj[ key || 'default' ] = suffix;
    const newMeta = document.createElement('meta');
    newMeta.name = mateName;
    newMeta.content = JSON.stringify(contentObj);
    document.head.appendChild(newMeta)
  }
  return suffix;
}

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test') {
  let suffix;
  if (process.env.ENABLE_FEDERATION === 'true') {
    suffix = getUi5SuffixAndSetMetaTag(suffix, process.env.APP_NAME);
  } else {
    suffix = process.env.APP_NAME;
  }

  setCustomElementsScopingSuffix(suffix);
  setCustomElementsScopingRules({
    include: [/^ui5-/],
    exclude: [/^ui5-object-page-anchor-tab/],
  });
}

// @ts-ignore
__webpack_public_path__ = window.__shell_webpack_public_path__ || '/';

const consoleError = console.error;
console.error = function (...args) {
  if (
    args?.length > 0 &&
    args[0]?.startsWith && 
    args[0]?.startsWith(
      'Warning: Detected multiple renderers concurrently rendering the same context provider',
    )
  ) {
    return;
  }
  return consoleError.apply(console, args);
};

// @ts-ignore
export default {};
