import React, { useRef } from 'react';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import { Button } from 'src/common/ui5dependencies';
import { ButtonDesign } from 'src/common/ui5dependencies';
import { MicroFrontendProps } from 'src/types';
import { css } from '@emotion/css';

const { useTranslation } = eureka.I18nProvider;
const { getConfig } = eurekaMgrs.ConfigManager;

type Props = Pick<MicroFrontendProps, 'eventBus'>;

const LogoutForm: React.FC<Props> = ({ eventBus }) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const { auth_provider, auth_method } = getConfig('user') || {};

  return (
    <div
      className={css`
        height: 132px;
        padding: 0 0.2rem;
        overflow: hidden;
      `}
    >
      <header
        className={css`
          border-bottom: 0.0625rem solid var(--sapPageFooter_BorderColor, #d9d9d9);
          padding: 0 0.5rem;
          height: 40px;
          display: flex;
          align-items: center;
        `}
      >
        <div>
          <h3>{t('Header_Confirmation')}</h3>
        </div>
      </header>
      <div
        className={css`
          padding: 1rem 0.5rem;
        `}
      >
        <div>
          <form ref={formRef} action="/auth/logout" method="post">
            <input type="hidden" name="auth_provider" value={auth_provider} />
            <input type="hidden" name="auth_method" value={auth_method} />
          </form>
          {t('Header_Logout_Confirmation')}
        </div>
      </div>
      <footer
        className={css`
          border-top: 0.0625rem solid var(--sapPageFooter_BorderColor, #d9d9d9);
          padding: 0.437rem 0.2rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        `}
      >
        <Button
          style={{ padding: '0 9px' }}
          onClick={() => {
            eventBus.emit('dialog-show-hide', '', {
              type: 'cancel',
              data: {
                showDialog: false,
              },
            });
          }}
          design={ButtonDesign.Transparent}
        >
          {t('Header_Logout_Btn_No')}
        </Button>
        <Button
          style={{ marginLeft: '10px', padding: '0 9px' }}
          design={ButtonDesign.Emphasized}
          onClick={() => {
            eventBus.emit('close-websocket', '', {
              type: 'logout',
              data: {},
            });
            setTimeout(() => {
              window.location.href = '/logout';
            }, 2000);
            eventBus.emit('dialog-show-hide', '', {
              type: 'confirm',
              data: {
                showDialog: false,
              },
            });
          }}
        >
          {t('Header_Logout_Btn_Yes')}
        </Button>
      </footer>
    </div>
  );
};

export default LogoutForm;
