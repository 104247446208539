import { useCallback, useMemo, useState } from 'react';
import { BusyIndicator, Button } from 'src/common/ui5dependencies';
import { css } from '@emotion/css';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Notification, Settings } from 'src/types';
import CustomNotification from './CustomNotification';
import { useTranslation } from './UserProfile/eureka';

const NotificationList = ({
  allNotifications,
  onDismissNotification,
  onDismissAllNotifications,
  settings,
  closeNotificationPopover,
}: {
  allNotifications: Notification[];
  onDismissNotification: (notification: Notification) => void;
  onDismissAllNotifications: () => void;
  settings: Settings;
  closeNotificationPopover: () => void;
}) => {
  const [pagination, setPagination] = useState({ pageIndex: 1, pageSize: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const loadMore = useCallback(() => {
    setTimeout(() => setPagination((x) => ({ ...x, pageIndex: x.pageIndex + 1 })), 1000);
  }, []);

  const dismissNotification = useCallback(
    async (notification: Notification) => {
      try {
        setIsLoading(true);
        onDismissNotification(notification);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [onDismissNotification],
  );

  const dismissAllNotifications = useCallback(async () => {
    try {
      setIsLoading(true);
      onDismissAllNotifications();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [onDismissAllNotifications]);

  const sortedNotificatins = useMemo(
    () =>
      allNotifications?.sort((item1, item2) => {
        const date1 = new Date(item1.receivedTime).getTime();
        const date2 = new Date(item2.receivedTime).getTime();
        return date2 - date1;
      }),
    [allNotifications],
  );

  const notifications = useMemo(
    () => sortedNotificatins?.slice(0, pagination.pageIndex * pagination.pageSize),
    [pagination, sortedNotificatins],
  );

  const hasMore = useMemo(
    () => pagination.pageIndex * pagination.pageSize < allNotifications?.length,
    [pagination, allNotifications],
  );

  return allNotifications?.length > 0 ? (
    <BusyIndicator
      className={css`
        width: 100%;
      `}
      active={isLoading}
      delay={0}
    >
      <div>
        <div
          className={css`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <Button
            design="Transparent"
            onClick={dismissAllNotifications}
            data-testid="notification-clear-all"
          >
            {t('Notification_ClearAll')}
          </Button>
        </div>
        <div
          id="notifications_container"
          className={css`
            width: 410px;
            padding: 0 10px 10px 10px;
            margin-top: 10px;
            max-height: 400px;
            overflow-y: scroll;
            overflow-x: hidden;
          `}
        >
          <InfiniteScroll
            dataLength={notifications.length}
            next={loadMore}
            hasMore={hasMore}
            scrollableTarget="notifications_container"
            loader={
              <BusyIndicator
                className={css`
                  width: 100%;
                `}
                active
                delay={0}
              />
            }
          >
            {notifications.map((x) => (
              <CustomNotification
                key={`notification_${x.messageId || x.id || x.receivedTime}`}
                notification={x}
                settings={settings}
                onCloseFunction={dismissNotification}
                closeNotificationPopover={closeNotificationPopover}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </BusyIndicator>
  ) : (
    <>{t('Notification_Empty')}</>
  );
};

export default NotificationList;
