/* eslint-disable @typescript-eslint/no-unused-vars */
import eureka from 'eureka';
import {
  FETCH_CONFIG_BEGIN,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_FAILURE,
  FETCH_CONFIG_DISMISS_ERROR,
  FETCH_TIMEZONE_BEGIN,
  FETCH_TIMEZONE_SUCCESS,
  FETCH_TIMEZONE_FAILURE,
  FETCH_TIMEZONE_DISMISS_ERROR,
} from './constants';

const { axiosCreate } = eureka.HttpWrapper;
const axiosWrapper = axiosCreate();
const axios = axiosWrapper.instance;
// If you prefer redux-saga, you can use rekit-plugin-redux-saga: https://github.com/supnate/rekit-plugin-redux-saga
export function fetchAppConfig(args = {}) {
  return (dispatch) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_CONFIG_BEGIN,
    });
    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form,
    // you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // See the real-word example at:  https://github.com/supnate/rekit/blob/master/src/features/home/redux/fetchRedditReactjsList.js
      // args.error here is only for test coverage purpose.
      const cfgRequest = axios.get('/config.json');
      cfgRequest.then(
        (res) => {
          dispatch({
            type: FETCH_CONFIG_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: FETCH_CONFIG_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });
    return promise;
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissFetchConfigError() {
  return {
    type: FETCH_CONFIG_DISMISS_ERROR,
  };
}

export function fetchTimeZone(args = {}) {
  return (dispatch) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_TIMEZONE_BEGIN,
    });
    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form,
    // you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // See the real-word example at:  https://github.com/supnate/rekit/blob/master/src/features/home/redux/fetchRedditReactjsList.js
      // args.error here is only for test coverage purpose.
      const timeZoneRequest = axios.get('/api/rgm-user-management/business/v1/users/rgm/timezones');
      timeZoneRequest.then(
        (res) => {
          dispatch({
            type: FETCH_TIMEZONE_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: FETCH_TIMEZONE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_CONFIG_BEGIN:
    case FETCH_TIMEZONE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchConfigPending: true,
        fetchConfigError: null,
      };

    case FETCH_CONFIG_SUCCESS:
      // The request is success
      return {
        ...state,
        config: action.data,
        needReloadConfig: false,
        fetchConfigPending: false,
        fetchConfigError: null,
      };

    case FETCH_CONFIG_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchConfigPending: false,
        fetchConfigError: action.data.error,
      };

    case FETCH_CONFIG_DISMISS_ERROR:
    case FETCH_TIMEZONE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchConfigError: null,
      };

    case FETCH_TIMEZONE_SUCCESS:
      // The request is success
      return {
        ...state,
        timezone: action.data,
        needReloadTimeZone: false,
        fetchTimeZonePending: false,
        fetchTimeZoneError: null,
      };

    case FETCH_TIMEZONE_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchTimeZonePending: false,
        fetchTimeZoneError: action.data.error,
      };

    default:
      return state;
  }
}
