import { combineReducers } from 'redux';
import commonReducer from '../features/common/redux/reducer';
import homeReducer from '../features/home/redux/reducer';

const reducerMap = {
  common: commonReducer,
  home: homeReducer,
};

const reducers = combineReducers(reducerMap);
export default reducers;