import React, { Component, Suspense } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import EurekaComponents from 'eureka';
import * as actions from './redux/actions';

import Header from './Header';
import SidePanel from './SidePanel';
import { ShellDialog } from '../plugins/dialog.plugin';
import MessageToast from '../plugins/toast.plugin';
import OptionalMessageBox from '../plugins/messagebox.plugin';
import { filterWithFeatureFlags } from '../../common/Utils';
import { LayoutProps } from 'src/types';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

const { Spinner } = EurekaComponents.components;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  React.PropsWithChildren<LayoutProps>;

export const DefaultLayout: React.FC<Props> = (props) => {
  const { showMenu } = props.common;

  console.log('props', props.config);
  return (
    <div id="eureka-shell-container" className="fd-shell fd-shell--fundamentals">
      <div className="default-shell__header">
        <Suspense fallback={<Spinner />}>
          <Header
            history={props.history}
            config={props.config}
            settings={props.settings}
            user={props.user}
          />
        </Suspense>
      </div>
      <div className="fd-shell__app">
        <div className="fd-app">
          <div
            style={{ overflow: 'visible', zIndex: 200, background: ThemingParameters.sapBackgroundColor }}
            className={`fd-app__navigation fd-app__navigation--vertical${
              showMenu ? ' show-menu ' : ' hide-menu '
            }`}
          >
            <Suspense fallback={<Spinner />}>
              <SidePanel
                history={props.history}
                match={props.match}
                config={filterWithFeatureFlags(props.config)}
              />
            </Suspense>
          </div>
          <main className="fd-app__main">
            <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
              {props.children}
            </Suspense>
          </main>
        </div>
      </div>
      <ShellDialog key="default-layout-dialog" />
      <MessageToast key="default-message-toast" />
      {/* <OptionalMessageBox /> */}
      {/* <div className="fd-shell__footer"><span>SAP</span></div> */}
    </div>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
