/* eslint-disable prefer-const */
/* eslint-disable implicit-arrow-linebreak */
import eurekaMgrs from '@eureka/ui-managers';
import eureka from 'eureka';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import '@ui5/webcomponents-icons/dist/copy';
// import '@ui5/webcomponents-icons/dist/json-imports/Icons.js';
// import '@ui5/webcomponents-icons/dist/log.js';
// import '@ui5/webcomponents-icons/dist/menu';
// import '@ui5/webcomponents-icons/dist/menu2';
// import '@ui5/webcomponents-icons/dist/product';
// import '@ui5/webcomponents-icons/dist/user-settings.js';
import {
  Button,
  ButtonDesign,
  Input,
  Popover,
  ShellBar,
  ListItemStandard,
  List,
  PopoverHorizontalAlign,
  PopoverPlacementType,
} from 'src/common/ui5dependencies';
import { PopoverDomRef } from 'src/common/ui5dependencies';

import * as actions from './redux/actions';
import LogoutForm from '../plugins/LogoutForm';
import { getAvatar } from '../plugins/profile.plugin';
import { searchInfo } from '../plugins/search.plugin';
import UserProfile from './UserProfile';
import logo from './logo';
import { MicroFrontendProps, ConfigJson } from 'src/types';
import NotificationList from './NotificationList';
import CustomNotification from './CustomNotification';

const { FragmentManager } = eurekaMgrs;
const { withTranslation } = eureka.I18nProvider;
const { eventBus } = eurekaMgrs;
const { addConfig } = eurekaMgrs.ConfigManager;

/* istanbul ignore next */
export const UserProfileFragment = ({
  config,
  history,
}: {
  config: ConfigJson;
  history: History;
}) => {
  const domRef = useRef(null);
  useEffect(() => {
    const container = domRef.current;
    const fragments = FragmentManager.getFragmentsWithTag('shell-user_profile', config);
    let renderMap = null;
    if (container) {
      renderMap = FragmentManager.renderFragments({
        container,
        fragments,
        renderFragment: (fragmentContainer, fragment) => {
          fragment.render(fragmentContainer, history, {
            config,
            eventBus,
          });
        },
      });
    }
    return () => {
      if (container) {
        FragmentManager.unmountFragments({ container, renderMap });
      }
    };
  }, []);
  return (
    <div ref={domRef} className="user-profile-fragment" style={{ width: '100%', height: '100%' }}>
      <UserProfile config={config} eventBus={eventBus} />
    </div>
  );
};

export const handleSidenav = ({ showMenu, acts }: { showMenu: boolean; acts: typeof actions }) => {
  if (showMenu) {
    acts?.hideMenu?.call(null);
  } else {
    acts?.showMenu?.call(null);
  }
  eventBus.emit('sidenav-show-hide', null, { collapsed: showMenu });
  addConfig('SidenavCollapsed', showMenu);
};

export const handleSearchInput = (searchInput: HTMLInputElement | null) => {
  const value = searchInput?.value;
  let suggestionItems = [];
  if (value) {
    suggestionItems = searchInfo.searchList.filter(
      (item: { text: string }) => item.text.toUpperCase().indexOf(value.toUpperCase()) === 0,
    );
  }
  // remove the previous suggestions
  [].slice.call(searchInput?.children).forEach((child) => {
    searchInput?.removeChild(child);
  });
  // add the new suggestions according to the  user input
  suggestionItems.forEach((item: { text: string }) => {
    const li = document.createElement('ui5-suggestion-item');
    // @ts-ignore
    li.text = item.text;
    searchInput?.appendChild(li);
  });
};

export const handleNotificationClick = ({
  event,
  notificationPopoverRef,
  setOpenNotificationPopover,
  setOpenProfileMenu,
}: {
  event;
  notificationPopoverRef: React.RefObject<PopoverDomRef>;
  setOpenNotificationPopover: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenProfileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  closeProfileMenu(setOpenProfileMenu);
  if (setOpenNotificationPopover && notificationPopoverRef?.current) {
    notificationPopoverRef.current.opener = event.detail.targetRef;
    setOpenNotificationPopover(true);
  }
};

export const handleProfileClick = ({
  event,
  setOpenNotificationPopover,
  setOpenProfileMenu,
}: {
  event;
  setOpenNotificationPopover: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenProfileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  if (setOpenProfileMenu && setOpenNotificationPopover) {
    setOpenNotificationPopover(false);
    setOpenProfileMenu(true);
  }
};

const openProfileSetting = (
  event: React.MouseEvent,
  config: ConfigJson,
  t: any,
  setOpenProfileMenu: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  closeProfileMenu(setOpenProfileMenu);
  eventBus.emit('dialog-show-hide', '', {
    type: 'user-profile',
    data: {
      showDialog: true,
      title: t('Header_ProfileSettings', 'Settings', { type: 'tit', desc: '' }),
      content: () => {
        return <UserProfile config={config} eventBus={eventBus} />;
      },
    },
  });
};

const closeProfileMenu = (setOpenProfileMenu?: React.Dispatch<React.SetStateAction<boolean>>) => {
  setOpenProfileMenu && setOpenProfileMenu(false);
};

const logout = (setOpenProfileMenu: React.Dispatch<React.SetStateAction<boolean>>) => {
  closeProfileMenu(setOpenProfileMenu);
  eventBus.emit('dialog-show-hide', '', {
    type: 'user-logout',
    data: {
      showDialog: true,
      content: <LogoutForm eventBus={eventBus} />,
    },
  });
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  Pick<MicroFrontendProps, 'history' | 'config' | 'settings' | 'user'> & { t: any };

export const Header: React.FC<Props> = ({
  user,
  config,
  common,
  actions,
  settings,
  history,
  t,
}) => {
  const enableJoule = eurekaMgrs.ConfigManager.getFeatureToggle(
    'rgp.9545.joule.rgm.integration.demo',
  );
  const notificationPopoverRef = useRef<PopoverDomRef>(null);
  const profileMenuRef = useRef<PopoverDomRef>(null);
  const searchInputRef = useRef(null);
  let { notifications, showMenu } = common;

  const [openNotificationPopover, setOpenNotificationPopover] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);

  useEffect(() => {
    addConfig('SidenavCollapsed', !showMenu);
  }, [showMenu]);

  useEffect(() => {
    eventBus.on('wa-loaded', () => {
      addConfig('waLoaded', true);
    });
  }, []);

  // disable websocket connection and notification
  useEffect(() => {
    actions?.startWebsocket && actions?.startWebsocket();
    return () => {
      actions?.closeWebsocket();
    };
  }, [actions]);

  useEffect(() => {
    const profileTimer = setTimeout(() => {
      const container =
        profileMenuRef.current?.getDomRef &&
        (profileMenuRef.current.getDomRef()?.querySelector('div.ui5-popup-content') as HTMLElement);
      container && (container.style.padding = '0');
    }, 0);
    return () => {
      clearTimeout(profileTimer);
    };
  }, [profileMenuRef]);

  const closeNotificationPopover = useCallback(() => {
    setOpenNotificationPopover(false);
  }, []);

  return (
    <div
      data-testid="rpp.header"
      style={{
        background: `var(--sapShellColor)`,
      }}
    >
      <ShellBar
        data-testid={'rpp.header.shellBar'}
        id="eureka-shellbar"
        className="shellbar"
        logo={<img alt="SAP Logo" src={logo} />}
        primaryTitle={t('App_Title')}
        profile={getAvatar(user)}
        startButton={
          <Button
            data-testid={'shellbarHamburgerButton'}
            className="shellbar-hamburger-button"
            icon={showMenu ? 'menu' : 'menu2'}
            design={ButtonDesign.Transparent}
            onClick={() => handleSidenav({ showMenu, acts: actions })}
            data-ui5-stable="menu-toggle-button"
          />
        }
        onProfileClick={(event) =>
          handleProfileClick({ event, setOpenNotificationPopover, setOpenProfileMenu })
        }
        showNotifications={false}
        notificationsCount={notifications?.length > 99 ? '99+' : notifications?.length}
        onNotificationsClick={(event) =>
          handleNotificationClick({
            event,
            notificationPopoverRef,
            setOpenNotificationPopover,
            setOpenProfileMenu,
          })
        }
        searchField={
          null
          // <Input
          //   data-testid={'rpp.header.searchInput'}
          //   ref={searchInputRef}
          //   type="Text"
          //   value={undefined}
          //   valueState="None"
          //   placeholder="Enter a term"
          //   showSuggestions
          //   // onSuggestionItemSelect={null}
          //   onInput={() => handleSearchInput(searchInputRef.current)}
          //   data-ui5-stable="search-input"
          // />
        }
      ></ShellBar>

      <Popover
        ref={notificationPopoverRef}
        open={openNotificationPopover}
        allowTargetOverlap
        horizontalAlign={PopoverHorizontalAlign.End}
        placement={PopoverPlacementType.Bottom}
        preventFocusRestore={false}
        onClose={closeNotificationPopover}
      >
        <NotificationList
          allNotifications={notifications}
          settings={settings}
          onDismissNotification={actions.dismissNotification}
          onDismissAllNotifications={actions.dismissAllNotifications}
          closeNotificationPopover={closeNotificationPopover}
        />
        {/* <div
          id="_eureka_claims_invoice_list_header_notification_menu"
          key="notifications1"
          className="sap-eureka-common__header__notification"
          style={{
            width: '410px',
            padding: '10px',
            maxHeight: '400px',
            overflow: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            {((notifications && notifications.slice(0, 66)) || []).map((notification, index) => {
              const { acknowledged } = notification;
              const style = {
                width: '100%',
                display: 'inline-block',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              };
              if (acknowledged) {
                Object.assign(style, {
                  color: '#bbbbbb',
                });
              }
              return (
                <CustomNotification
                  key={`notification_${notification.id}`}
                  notification={notification}
                  settings={settings}
                  onCloseFunction={() => actions.dismissNotification(notification)}
                />
              );
            })}
          </div>
        </div> */}
      </Popover>

      <Popover
        data-testid={'header-profile-popover'}
        open={openProfileMenu}
        ref={profileMenuRef}
        opener={'rgm-shellbar-profile-avatar'}
        allowTargetOverlap
        horizontalAlign={PopoverHorizontalAlign.End}
        placement={PopoverPlacementType.Bottom}
        preventFocusRestore={false}
        onClose={() => setOpenProfileMenu(false)}
      >
        <List>
          <ListItemStandard
            type="Active"
            icon={'user-settings'}
            selected={false}
            onClick={(evt) => openProfileSetting(evt, config, t, setOpenProfileMenu)}
            // style={{ margin: '3px' }}
            data-key="1"
            // infoState="None"
          >
            {t('Profile_Settings', 'Settings', { type: 'but', desc: '' })}
          </ListItemStandard>
          <ListItemStandard
            type="Active"
            icon="log"
            selected={false}
            onClick={() => logout(setOpenProfileMenu)}
            // style={{ margin: '2px' }}
            data-key="2"
            // infoState="None"
          >
            {t('Header_Logout')}
          </ListItemStandard>
        </List>
      </Popover>
    </div>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));
