import React from 'react';
import ErrorComponent from '../common/ErrorComponent';
import { MicroFrontendProps } from 'src/types';

type Params = { id: string };

const SyetemErrorPage: React.FC<MicroFrontendProps<Params>> = (props) => {
  const errorType = props.match.params.id;
  const query = window.location.search;
  let errorCode = '';
  if (query) errorCode = decodeURIComponent(query.split('=')[1]);
  console.log('props', props)
  return (
    <ErrorComponent props={props} errorType={errorType} errorCode={errorCode}></ErrorComponent>
  );
};

export default SyetemErrorPage;
