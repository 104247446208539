import React, { Suspense } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import EurekaComponents from 'eureka';
import * as actions from './redux/actions';
import { ShellDialog } from '../plugins/dialog.plugin';
import MessageToast from '../plugins/toast.plugin';
import OptionalMessageBox from '../plugins/messagebox.plugin';
import { LayoutProps } from 'src/types';

const { Spinner } = EurekaComponents.components;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  React.PropsWithChildren<LayoutProps>;

export const MashupLayout: React.FC<Props> = (props) => {
  return (
    <div className="fd-shell fd-shell--fundamentals">
      <div
        style={{
          minHeight: '100vh !important',
          height: '100vh !important',
        }}
      >
        <div className="fd-app" style={{ height: '100vh' }}>
          <main className="fd-app__main">
            <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
              {props.children}
            </Suspense>
          </main>
        </div>
      </div>
      <ShellDialog key="default-layout-dialog" />
      <MessageToast key="default-message-toast" />
      {/* <OptionalMessageBox /> */}
    </div>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MashupLayout);
