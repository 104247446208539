import React, { useEffect, Suspense } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { Header, SidePanel } from '../common';
import Tiles from './Tiles';
import { ShellDialog } from '../plugins/dialog.plugin';
import MessageToast from '../plugins/toast.plugin';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import { filterWithFeatureFlags } from '../../common/Utils';
import { MicroFrontendProps } from 'src/types';
import { useHistory } from 'rgp-ui-lib';

const { Spinner } = eureka.components;
const { OptionalMessageBox } = eureka.controls;

const navigatePage = (history, config, mfe, router) => {
  if (
    config.components &&
    (config.components || []).filter((c) => c.name === mfe).length > 0
  ) {
    history.push(router);
  }
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  MicroFrontendProps;

export const WelcomePage: React.FC<Props> = (props) => {
  const history = useHistory();
  const { showMenu } = props.common;

  useEffect(() => {
    navigatePage(history, props.config, 'rgp-promotion-planning-ui', '/promotion-planning/account-plan-dashboard');
  }, []);

  return (
    <div className="fd-shell fd-shell--fundamentals">
      <div className="fd-shell__header">
        <Suspense fallback={<Spinner />}>
          <Header
            history={history}
            config={props.config}
            settings={props.settings}
            user={props.user}
            // eventBus={props.eventBus}
          />
        </Suspense>
      </div>
      <div className="fd-shell__app">
        <div className="fd-app">
          <div
            className={`fd-app__navigation fd-app__navigation--vertical${
              showMenu ? ' show-menu' : ' hide-menu'
            }`}
          >
            <Suspense fallback={<Spinner />}>
              <SidePanel
                history={props.history}
                match={props.match}
                config={filterWithFeatureFlags(props.config)}
              />
            </Suspense>
          </div>
          <main className="fd-app__main">
            <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
              <div className="home-page">
                <Tiles history={props.history} config={props.config} />
              </div>
            </Suspense>
          </main>
        </div>
      </div>
      <ShellDialog key="welcome-page-dialog" />
      <MessageToast key="welcome-page-toast" />
      {/* <OptionalMessageBox /> */}
      {/* <div className="fd-shell__footer"><span>SAP</span></div> */}
    </div>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
