import { WelcomePage, ErrorPage, SystemErrorPage } from '.';

export default {
  path: '/',
  name: 'Home',
  component: null,
  childRoutes: [
    { path: 'welcome-page', name: 'Default page', component: WelcomePage, isIndex: true },
    { path: 'error', name: 'Error page', component: ErrorPage },
    { path: 'error/:id', name: 'Error page', component: ErrorPage },
    { path: 'system-error', name: 'Error page', component: SystemErrorPage },
    { path: 'system-error/:id', name: 'Error page', component: SystemErrorPage },
  ],
};
