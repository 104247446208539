import React from 'react';
import { AvatarShape, AvatarSize } from 'src/common/ui5dependencies';
import '@ui5/webcomponents-icons/dist/employee.js';
import MyAvatar from './MyAvatar';
import { UserInfo } from 'src/types';

export const getAvatar = (user: UserInfo | Record<string, unknown>, profileAvatarRef) => {
  let avatar;
  let type;
  let value;
  if (user && 'avatarIcon' in user) {
    avatar = user.avatarIcon;
    type = avatar.type?.toLocaleLowerCase();
    value = avatar;
  }
  return (
    <MyAvatar
      id={'rgm-shellbar-profile-avatar'}
      initials={type === 'initials' ? value : ''}
      image={type === 'image' ? value : null}
      // icon={type === 'genderneutralicon' ? 'employee' : ''}
      icon={'employee'}
      shape={AvatarShape.Circle}
      size={AvatarSize.XS}
      type={type}
      value={value}
    />
  );
};
