import React from 'react';

type Props = React.PropsWithChildren<Record<string, unknown>>;
/*
  This is the root component of your app. Here you define the overall layout
  and the container of the react router.
  You should adjust it according to the requirement of your app.
*/
const App: React.FC<Props> = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};
export default App;
