/* eslint-disable operator-linebreak */
import { useState, useEffect, useRef } from 'react';
import { Dialog } from 'src/common/ui5dependencies';
import eurekaMgrs from '@eureka/ui-managers';
import { DialogDomRef } from 'src/common/ui5dependencies';

const { eventBus } = eurekaMgrs;

type HideDialogAction = {
  type: 'cancel';
  data: {
    showDialog: boolean;
    content?: any;
  };
};

const onDialogShowHide = (action: HideDialogAction, dialogRef, properties, setProperties) => {
  const { data } = action;
  if (data) {
    const localData = { ...data };
    localData.showDialog = data.showDialog;
    if (data.content) {
      localData.content = typeof data.content === 'function' ? data.content() : data.content;
    }
    setProperties({
      ...properties,
      ...localData,
    });
    if (localData.showDialog) {
      dialogRef?.current?.showPopover();
    } else {
      dialogRef?.current?.hide();
    }
  }
};

export const ShellDialog = () => {
  const dialogRef = useRef<DialogDomRef>(null);
  const [properties, setProperties] = useState({
    showDialog: false,
    content: '',
    hideHeader: true,
    footer: null,
    stretch: false,
    title: '',
    clearPadding: true,
  });
  useEffect(() => {
    eventBus.on('dialog-show-hide', (action) =>
      onDialogShowHide(action, dialogRef, properties, setProperties),
    );
    let timer;
    if (properties.clearPadding) {
      timer = setTimeout(() => {
        const container =
          dialogRef.current?.getDomRef &&
          (dialogRef.current.getDomRef()?.querySelector('div.ui5-popup-content') as HTMLElement);
        container && (container.style.padding = '0');
      }, 0);
    }
    return () => {
      clearTimeout(timer);
      eventBus.detach('dialog-show-hide');
    };
  }, [dialogRef, properties]);
  return (
    <Dialog
      ref={dialogRef}
      open={properties.showDialog}
      // hideHeader={properties.hideHeader}
      stretch={properties.stretch}
      title={properties.title}
      footer={properties.footer}
      // TODO:
      onAfterClose={() => {
        setProperties({
          ...properties,
          showDialog: false,
        });
        dialogRef.current?.close();
      }}
    >
      {properties.content}
    </Dialog>
  );
};

/* istanbul ignore next */
export const closeShellDialog = () => {
  eventBus.emit('dialog-show-hide', '', {
    type: 'cancel',
    data: {
      showDialog: false,
    },
  });
};
