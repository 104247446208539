const initialState = {
  notification: [],
  showMenu: false,
  userPermissions: [],
  needReloadUserPermissions: true,
  fetchUserPermissionsPending: false,
  fetchUserPermissionsError: false,
  config: null,
  needReloadConfig: true,
  fetchConfigPending: false,
  fetchConfigError: false,
  needReloadTimeZone: true,
  fetchTimeZonePending: false,
  fetchTimeZoneError: false,
};

export const getInitialState = () => initialState;

export default initialState;
