import { Reducer } from 'redux';
import { HomeState } from 'src/types';
import initialState from './initialState';

const reducers = [];

const reducer: Reducer<HomeState> = (state = initialState, action) => {
  let newState: HomeState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  // @ts-ignore
  return reducers.reduce((s, r) => r(s, action), newState);
};
export default reducer;
